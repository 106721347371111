import { required } from "vuelidate/lib/validators";

export default (valor) => {
    let validationBrach = false;
    if (valor) {
        validationBrach = { required };
    }
    return {
        ClientId: { required },
        TpClientId: { required },
        sucursal: validationBrach,
    }
}

